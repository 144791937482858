/* eslint-disable */
import { Entity, EntityMeta } from 'apptimizm-ui'

class ListItem {
  name: string = ''
  value: string = ''
}

class CurrencyItem {
  title: string = ''
  code: string = ''
  symbol: string = 'Р'
  id: number = 0
}

interface Constance {
  key: string
  value: Array<string>
}

class Accounts extends Entity {
  id: string = ''
  title: string = ''
  iconBackgroundColor: string = ''
  currency: CurrencyItem = new CurrencyItem()
  totalAmount: number = 0
  sharedUsers: any = []
  isShared: Boolean = false
  usersKey: String = ''
}

class AccountsMeta extends EntityMeta<Accounts> {
  endpoint: string = '/bank_accounts/'

  fields = {
    id: { type: String },
    title: { type: String },
    iconBackgroundColor: { type: String, backendKey: 'icon_background_color' },
    currency: { type: Object },
    totalAmount: { type: Number, backendKey: 'total_amount' },
    isShared: { type: Boolean, backendKey: 'is_shared' },
    sharedUsers: { type: Array, backendKey: 'shared_users' },
    usersKey: { type: String, backendKey: 'users_key' }
  }

  dump(item: Accounts): { [code: string]: any } {
    const result = super.dump(item)
    result.currency = item.currency.id
    return result
  }
}

export class EnumType extends Entity {
  name: string = ''
  value: string = ''
}

export class EnumTypeMeta extends EntityMeta<EnumType> {
  entity = EnumType

  fields = {
    id: { type: String, backendKey: 'value' },
    name: { type: String },
    value: { type: String }
  }
}

export class Icon extends Entity {
  type: EnumType = new EnumType()
  file: string = ''
}

export class IconMeta extends EntityMeta<Icon> {
  entity = Icon

  fields = {
    id: { type: String },
    type: { type: EnumTypeMeta },
    file: { type: String }
  }
}

export class CategorySetting extends Entity {
  id: string = ''
  category: EnumType = new EnumType()
  icon: Icon = new Icon()
  backgroundColor: string = '#242424'
}

export class CategorySettingMeta extends EntityMeta<CategorySetting> {
  entity = CategorySetting
  endpoint: string = '/category_setting/'

  fields = {
    id: { type: String },
    category: { type: EnumTypeMeta },
    icon: { type: IconMeta },
    backgroundColor: { type: String, backendKey: 'icon_background_color' }
  }

  dump (item: CategorySetting): { [code: string]: any } {
    const result = super.dump(item)

    if (item.category.value) result.category = item.category.value
    if (item.icon.id) result.icon = item.icon.id

    return result
  }
}

export class ExpensesType extends Entity {
  id: string = ''
  title: string = ''
  categorySetting: CategorySetting = new CategorySetting()
  icon: Icon = new Icon()
  backgroundColor: string = ''
  canDeleted: boolean = false
}

export class ExpensesTypeMeta extends EntityMeta<ExpensesType> {
  entity = ExpensesType
  endpoint: string = '/expenses_type/'

  fields = {
    id: { type: String },
    title: { type: String },
    categorySetting: { type: CategorySettingMeta, backendKey: 'category_setting' },
    icon: { type: IconMeta },
    backgroundColor: { type: String, backendKey: 'icon_background_color' },
    canDeleted: { type: Boolean, backendKey: 'can_deleted' }
  }

  dump (item: ExpensesType): { [code: string]: any } {
    const result = super.dump(item)
    if (item.icon) result.icon = item.icon.id

    return result
  }
}

export class ExpensesName extends Entity {
  id: string = ''
  title: string = ''
  canDeleted: boolean = false
  expensesType: ExpensesType = new ExpensesType()
}

export class ExpensesNameMeta extends EntityMeta<ExpensesName> {
  entity = ExpensesName
  endpoint: string = '/expenses_name/'

  fields = {
    id: { type: String },
    title: { type: String },
    canDeleted: { type: Boolean, backendKey: 'can_deleted' },
    expensesType: { type: ExpensesTypeMeta, backendKey: 'expenses_type' }
  }

  dump (item: ExpensesName): { [code: string]: any } {
    const result = super.dump(item)

    if (item.expensesType.id) result.expenses_type = item.expensesType.id
    else result.expenses_type = null

    return result
  }
}

export class Transaction extends Entity {
  id: string = ''
  amount: string = ''
  expensesName: ExpensesName = new ExpensesName()
  expensesType: ExpensesType = new ExpensesType()
  paymentDatetime: string = ''
  categorySetting: CategorySetting = new CategorySetting()
  bankAccont: Accounts = new Accounts()
  note: string = ''
}

export class TransactionMeta extends EntityMeta<Transaction> {
  endpoint: string = '/transaction/'

  fields = {
    id: { type: String },
    amount: { type: String },
    expensesName: { type: Object, backendKey: 'expenses_name' },
    expensesType: { type: Object, backendKey: 'expenses_type' },
    categorySetting: { type: Object, backendKey: 'category_setting' },
    paymentDatetime: { type: String, backendKey: 'payment_datetime' },
    bankAccont: { type: Object, backendKey: 'bank_account' },
    note: { type: String }
  }

  load (data: any): Transaction {
    const result = super.load(data)

    if (data.expenses_name) result.expensesName = new ExpensesNameMeta().load(data.expenses_name)
    if (data.expenses_type) result.expensesType = new ExpensesTypeMeta().load(data.expenses_type)
    if (data.category_setting) result.categorySetting = new CategorySettingMeta().load(data.category_setting)
    if (data.bank_account) result.bankAccont = new AccountsMeta().load(data.bank_account)

    return result
  }

  dump (item: Transaction): { [code: string]: any } {
    const result = super.dump(item)

    if (item.bankAccont) result.bank_account = item.bankAccont.id
    if (item.expensesName) result.expenses_name = item.expensesName.id
    if (item.expensesType) result.expenses_type = item.expensesType.id
    if (item.categorySetting) result.category_setting = item.categorySetting.id

    return result
  }
}

class GenericErrorEvent extends Event {
  errors: string[] = []
}

const BaseAccount = new Accounts()
BaseAccount.title = 'Все счета'
BaseAccount.iconBackgroundColor = '#6553F5'

export {
  ListItem,
  Accounts,
  AccountsMeta,
  Constance,
  GenericErrorEvent,
  CurrencyItem,
  BaseAccount
}
