import { useAxios } from 'apptimizm-ui'

export class ApiErrorEvent extends Event {
  nonFieldErrors: string[] = []
}

const getPath = (item: string) => item.includes('bank_accounts') || item.includes('transaction')

export const axios = useAxios(
  config => {
    if (!config.headers) config.headers = {}
    // if (localStorage.token) config.headers.Token = 'JWT ' + localStorage.token
    if (localStorage.token && !config.headers.Token) config.headers.Token = 'JWT ' + localStorage.token
    return config
  },
  url => {
    if (getPath(url)) {
      if (url.indexOf('http') !== 0) url = process.env.VUE_APP_API_HOST_2 + url
    } else {
      if (url.indexOf('http') !== 0) url = process.env.VUE_APP_API_HOST + url
    }
    return url
  }
)
