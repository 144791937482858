import { defineComponent } from 'vue'
import Header from '@/core/layout/header/header'
import Footer from '@/core/layout/footer/footer'

export default defineComponent({
  setup () {
    return () => <>
      <Header />
      <div class='mock-container'>
        <div class="mock-container-text">
          <p class='mock-title'>Бюджет</p>
          <p class='mock-subtitle'>Раздел находится в разработке...</p>
          <p class='mock-text'>В ближайшее время мы подключим этот раздел и вы сможете им воспользоваться!</p>
          <p class='mock-text'>Следите за обновлениями <a href='https://profinansy.ru/feed/updates' style={{ color: '#6553F5', cursor: 'pointer' }}>в рассылке!</a>
          </p>
          <p class='mock-text-bold'>Вы можете воспользоваться данным разделом в мобильным приложении</p>
          <div class='mock-btns'>
            <a style={{ cursor: 'pointer' }} href={'https://apps.apple.com/ru/app/pro-finansy-%D0%BF%D1%80%D0%BE-%D1%84%D0%B8%D0%BD%D0%B0%D0%BD%D1%81%D1%8B/id1636352042'}><img src={'/AppStore.svg'} alt='AppStore'/></a>
            <a style={{ cursor: 'pointer' }} href={'https://play.google.com/store/apps/details?id=com.profinansy.profinansy'}><img src={'/GooglePlay.svg'} alt='GooglePlay'/></a>
            <a style={{ cursor: 'pointer' }} href={'https://appgallery.huawei.com/app/C107227589'}><img src={'/AppGallery.svg'} alt='AppGallery'/></a>
          </div>
          <a href='https://profinansy.ru/market/dashboard' class='mock-btn'>Вернуться назад</a>
        </div>
        <img src={'/MockBg.svg'} alt='Bg'/>
      </div>
      <Footer/>
    </>
  }
})