import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes = [
  {
    name: 'index',
    path: '/',
    component: () => import('./index/')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
] as RouteRecordRaw[]

export const router = createRouter({
  history: createWebHistory(),
  routes
})
