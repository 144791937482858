import { ListItem } from '@/core/types'
import { useClickOutside } from 'apptimizm-ui'
import { defineComponent, inject, PropType, Ref, ref } from 'vue'

class NavItem extends ListItem {
  icon: () => JSX.Element = () => <></>
}

const list: NavItem[] = [
  { name: 'Профиль', value: '/account/profile', icon: () => <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 10c2.7 0 5.8 1.29 6 2h-12c.23-.72 3.31-2 6-2zm0-12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="#242424"></path></svg> },
  { name: 'История покупок', value: '/account/purchase-history', icon: () => <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.046 11c.75 0 1.41-.41 1.75-1.03l3.58-6.49a.996.996 0 00-.87-1.48h-14.8l-.94-2H.496v2h2l3.6 7.59-1.35 2.44c-.73 1.34.23 2.97 1.75 2.97h12v-2h-12l1.1-2h7.45zm-9.39-7h12.15l-2.76 5h-7.02l-2.37-5zm.84 12c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" fill="#242424"></path></svg> },
  { name: 'Каналы', value: '/account/channels', icon: () => <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.578 6.407c4.63-2.017 7.717-3.347 9.261-3.99C15.25.583 16.166.264 16.763.254c.132-.002.425.03.616.184a.67.67 0 01.226.43 2.8 2.8 0 01.026.626c-.239 2.51-1.273 8.604-1.799 11.417-.223 1.19-.66 1.59-1.085 1.628-.922.085-1.623-.61-2.516-1.195-1.398-.916-2.187-1.486-3.544-2.38-1.568-1.034-.551-1.602.342-2.53.234-.242 4.297-3.938 4.376-4.274.01-.042.019-.198-.074-.28-.093-.083-.23-.055-.329-.032-.14.032-2.373 1.507-6.698 4.427-.634.435-1.208.647-1.723.636-.567-.012-1.657-.32-2.468-.584C1.118 8.004.328 7.833.397 7.284c.036-.286.43-.579 1.181-.877z" fill="#383838"></path></svg> },
  { name: 'Выйти', value: '', icon: () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.375 8.25h8.7l-2.55-2.55L13.6 4.625l4.4 4.4-4.35 4.35-1.075-1.075 2.55-2.55h-8.75v-1.5zM8.775 0v1.5H1.5v15h7.275V18H1.5c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-15C0 1.1.15.75.45.45.75.15 1.1 0 1.5 0h7.275z" fill="#383838"></path></svg> }
]

export default defineComponent({
  props: {
    onClose: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup (props) {
    const onLogout = inject('onLogout', (forcibly: boolean) => forcibly)
    const element = ref(null) as unknown as Ref<HTMLElement>
    let clickedCount = 0
    useClickOutside(element, () => {
      clickedCount += 1
      if (clickedCount > 1) props.onClose()
    })

    return () => <div class='header-user-panel' ref={element} style={{ boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)' }}>
      <p class='header-user-panel-head'>Персональные данные</p>
      { list.map(l => <>
        {l.value ? <a class='header-user-panel-item flex' href={ process.env.VUE_APP_AUTH_SERVICE + l.value }>
          <div class='header-user-panel-item-icon mr-2'>{ l.icon() }</div>
          <div>{l.name}</div>
        </a> : <div class='header-user-panel-item flex' onClick={() => onLogout(true)}>
          <div class='header-user-panel-item-icon mr-2'>{ l.icon() }</div>
          <div>{l.name}</div>
        </div>}
      </>)}
    </div>
  }
})
