import { createStore } from 'vuex'
import { Expense } from '@/components/charts/types'
import moment, { Moment } from 'moment'
moment.locale('ru')

export default createStore({
  state: {
    date: null,
    total: 0,
    expenses: []
  },
  getters: {},
  mutations: {
    setDate (state: any, date: Moment | null) {
      state.date = date
    },
    setTotal (state: any, total: number) {
      state.total = total
    },
    setExpenses (state: any, expenses: Expense[]) {
      state.expenses = expenses
    }
  },
  actions: {}
})
