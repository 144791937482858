import './styles/main.sass'
import { defineComponent, onMounted, provide, Ref, ref } from 'vue'
import { RouterView } from 'vue-router'
import Layout from './core/layout'
import { useFavicon } from './composables/use-favicon'
import { axios } from './axios'
import MockPage from '@/components/MockPage'

export default defineComponent({
  setup () {
    const isLoading: Ref<boolean> = ref(true)
    const userAvatar: Ref<string | null> = ref(null)
    const isPaid: Ref<boolean> = ref(false)
    document.title = 'PRO.FINANSY'
    useFavicon('/favicon.ico')

    const onLogout = (forcibly?: boolean) => {
      if (forcibly) {
        axios.post(
          `${process.env.VUE_APP_AUTH_SERVER}/auth/logout`,
          {},
          { headers: { Token: localStorage.getItem('token') }, withCredentials: true }
        )
      }
      localStorage.clear()
      if (window) {
        location = `${process.env.VUE_APP_AUTH_SERVICE}/authorization?redirect=${location.protocol}//${location.host}` as unknown as Location
      }
    }

    provide('onLogout', onLogout)
    provide('userAvatar', userAvatar)
    provide('isPaid', isPaid)

    onMounted(() => {
      axios.get(`${process.env.VUE_APP_AUTH_SERVER}/auth/session?type=web&_lang=ru`, { withCredentials: true })
        .then(({ data }) => {
          if (data.authorized) {
            localStorage.setItem('token', data.token)
            if (data.rights.ext) isPaid.value = data.rights.ext === 1
          } else onLogout()
        })
        .then(() => {
          axios.get(`${process.env.VUE_APP_AUTH_SERVER}/auth/user`, { headers: { Token: localStorage.getItem('token') } })
            .then(({ data }) => {
              if (data.avatar_url) userAvatar.value = data.avatar_url
            })
        })
        .finally(() => { isLoading.value = false })
        .catch(onLogout)

      window.addEventListener('401 Unauthorized', () => onLogout())

      const script = document.createElement('script')
      script.src = '//code.jivo.ru/widget/VPbjtbTXTK'
      script.async = true
      document.body.appendChild(script)
    })

    return () => <>
      {!isLoading.value && <MockPage>
        <RouterView/>
      </MockPage>}
    </>
  }
})
